import React from 'react'

import { LinkD } from '../components/linkd';
import Seo from '../components/seo'

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const TermsPage = () => (
  <>
    <Seo title="Terms of Service" />
    <Paper sx={{padding: '0.5em 0.5em 1em'}}>
      <Typography variant='h4' component='h1'>
        Terms of Service
      </Typography>
  The following terms and conditions ('Terms') govern all use of the werner-digital.com website
and all content, services, and products available at or through the website, including, but not
limited to, access to hosted software and content, (taken together, our 'Services').
   <p>
   Our Services are offered subject to your
acceptance without modification of all of the terms and conditions contained herein and all
other operating rules, policies (including, without limitation, werner-digital.com Privacy Policy) and
procedures that may be published from time to time by Werner Digital Technology, Inc (collectively, the
'Agreement'). You agree that we may automatically upgrade our Services, and these Terms
will apply to any upgrades.
</p><p>
Please read this Agreement carefully before accessing or using our Services. By accessing or
using any part of our Services, you agree to become bound by the Terms of this Agreement. If
you do not agree to all the Terms of this Agreement, then you may not access or use any of
our Services. If these Terms are considered an offer by Werner Digital Technology,Inc,  acceptance is expressly limited to these Terms.
  </p><p>
  Our Services are not directed to children younger than 18 and access and use of our Services
is only offered to users 18 years of age or older. If you are under 18 years old, please do not
register to use our Services. Any person who registers as a user or provides their personal
information to our Services represents that they are 18 years of age or older.
</p><p>
Use of our Services requires an account. You agree to provide us with
complete and accurate information when you register for an account. You will be solely
responsible and liable for any activity that occurs under your username. You are responsible
for keeping your password secure.
    </p>
  <ol>
    <li><h3>Termination</h3>
    Werner Digital Technology, Inc may terminate your access to all or any part of our Services at any time, with or
without cause, with or without notice, effective immediately. If you wish to terminate this
Agreement or your account (if you have one), you may simply discontinue
using our Services after closing your account. All provisions of this Agreement which by their nature should survive
termination shall survive termination, including, without limitation, ownership provisions,
warranty disclaimers, indemnity and limitations of liability.
    </li>
    <li><h3>Disclaimer of Warranties</h3>
Our Services are provided 'as is.' Werner Digital Technology, Inc and its
suppliers and licensors hereby disclaim all warranties of any kind, express
or implied, including, without limitation, the warranties of merchantability,
fitness for a particular purpose and non-infringement. Neither Werner Digital
Technology, Inc nor its suppliers and licensors, makes any warranty that our
Services will be error free or that access thereto will be continuous or uninterrupted.
You understand that you download from, or otherwise obtain content or services through,
our Services at your own discretion and risk.
    </li>
    <li><h3>Limitation of Liability</h3>
    In no event will Werner Digital Technology, Inc, or its suppliers or licensors, be liable with respect to any subject
matter of this Agreement under any contract, negligence, strict liability or other legal or
equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of
procurement for substitute products or services; (iii) for interruption of use or loss or corruption
of data; or (iv) for any amounts that exceed the fees paid by you to Werner Digital Technology, Inc under this
Agreement during the twelve (12) month period prior to the cause of action. Werner Digital Technology, Inc shall
have no liability for any failure or delay due to matters beyond their reasonable control. The
foregoing shall not apply to the extent prohibited by applicable law.
    </li>
    <li><h3>Miscellaneous</h3>
    This Agreement constitutes the entire agreement between Werner Digital Technology, Inc and you concerning the
subject matter hereof, and they may only be modified by a written amendment signed by an
authorized executive of Werner Digital Technology, Inc, or by the posting by Werner Digital Technology, Inc of a revised version.
If any part of this Agreement is held invalid or unenforceable, that part will be construed to
reflect the parties' original intent, and the remaining portions will remain in full force and effect.
A waiver by either party of any term or condition of this Agreement or any breach thereof, in
any one instance, will not waive such term or condition or any subsequent breach thereof.
You may assign your rights under this Agreement to any party that consents to, and agrees to
be bound by, its terms and conditions; Werner Digital Technology, Inc may assign its rights under this Agreement
without condition. This Agreement will be binding upon and will inure to the benefit of the
parties, their successors and permitted assigns.
    </li>
  </ol>
    <p>
        Please
    <LinkD to="/contact"> Contact&nbsp;Us </LinkD>
        for questions or more information about our website terms of service.
    </p>
    </Paper>
  </>
)

export default TermsPage
